<template>

    <h1>Nutzer</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6">
                        <label for="username" class="block text-sm font-medium text-gray-700">
                            <b>Username <a href="#"
                                           class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                                           data-bs-toggle="tooltip"
                                           title="Ein neuer Nutzer benötigt einen Nutzernamen. Der Name muss mindestens 5 Zeichen lang sein.">*</a></b><br>
                            Username, um sich bei ReportX anzumelden.
                        </label>
                        <input v-model="user.username" type="text" name="username" id="username"
                               class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <div class="col-span-6">
                        <label for="password" class="block text-sm font-medium text-gray-700">
                            <b>Passwort <a href="#"
                                           class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                                           data-bs-toggle="tooltip"
                                           title="Wähle ein sicheres Passwort. Ein neuer Nutzer kann nicht ohne Passwort erstellt werden">*</a></b><br>
                            Passwort, um sich bei ReportX anzumelden.
                        </label>
                        <input v-model="user.password" type="password" name="password" id="password"
                               class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="signerName" class="block text-sm font-medium text-gray-700">
                            <b>Unterzeichnender</b><br>
                            Name, der auf Berichten angezeigt wird.
                        </label>
                        <input v-model="user.signerName" name="signerName" id="signerName"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="name" class="block text-sm font-medium text-gray-700">
                            <b>Name <a href="#"
                                       class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                                       data-bs-toggle="tooltip"
                                       title="Der Name muss mindestens 5 Zeichen lang sein. Ein neuer Nutzer kann nicht ohne Namen erstellt werden">*
                            </a></b><br>
                            Vor-und Nachname des Nutzers. Dieser Name wird neben den Berichten als Bearbeiter verwendet
                            und auf den Berichten, falls kein Signer Name angegeben ist.
                        </label>
                        <input v-model="user.name" name="name" id="name"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="enabled" class="block text-sm font-medium text-gray-700">
                            <b>Account aktiv</b><br>
                            Wenn nicht ausgewählt, kann sich Nutzer nicht anmelden.
                        </label>
                        <input type="checkbox" v-model="user.enabled" name="enabled" id="enabled"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="accountExpired" class="block text-sm font-medium text-gray-700">
                            <b>Account abgelaufen</b><br>
                            Wenn ausgewählt ist der Account nicht mehr aktiv.
                        </label>
                        <input type="checkbox" v-model="user.accountExpired" name="accountExpired" id="accountExpired"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="accountLocked" class="block text-sm font-medium text-gray-700">
                            <b>Account gesperrt</b><br>
                            Ausgewählt, wenn auf Nutzer zu viele Fehlerhafte Anmeldeversuche bemerkt wurden.
                        </label>
                        <input type="checkbox" v-model="user.accountLocked" name="accountLocked" id="accountLocked"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="passwordExpired" class="block text-sm font-medium text-gray-700">
                            <b>Password abgelaufen</b><br>
                            Wenn ausgewählt, muss der Nutzer sein Passwort ändern.
                        </label>
                        <input type="checkbox" v-model="user.passwordExpired" name="passwordExpired"
                               id="passwordExpired"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="importTechnology" class="block text-sm font-medium text-gray-700">
                            <b> Technologie importieren</b><br>
                            Wenn ausgewählt, wird die verwendete Technologie im Bericht mit in das Clipboard kopiert.
                        </label>
                        <input type="checkbox" v-model="user.importTechnology" name="importTechnology"
                               id="importTechnology"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="importResults" class="block text-sm font-medium text-gray-700">
                            <b> Befund Importieren</b><br>
                            Wenn ausgewählt wird der Befund im Bericht mit in das Clipboard kopiert.
                        </label>
                        <input type="checkbox" v-model="user.importResults" name="importResults" id="importResults"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="importEvaluation" class="block text-sm font-medium text-gray-700">
                            <b>Auswertung importieren</b><br>
                            Wenn ausgewählt wird die geschriebene Auswertung mit in das CLipboard kopiert.
                        </label>
                        <input type="checkbox" v-model="user.importEvaluation" name="importEvaluation"
                               id="importEvaluation"
                               class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="imagePreviewType" class="block text-sm font-medium text-gray-700">
                            <b>Bild Vorschau Format</b><br>
                            Das Format in dem die Vorschaubilder angezeigt werden sollen.
                        </label>
                        <select id="imagePreviewType" v-model="user.imagePreviewType">
                            <option v-for="imageType in imagePreviewTypes" :key="imageType"
                                    :value="imageType">
                                {{ imageType }}
                            </option>
                        </select>
                    </div>

                    <div class="col-span-6 sm:col-span-6">
                        <label for="imagePreviewSize" class="block text-sm font-medium text-gray-700">
                            <b>Bild Vorschau Größe</b><br>
                            Die Größe in der die Vorschaubilder angezeigt werden sollen.
                        </label>
                        <select id="imagePreviewSize" v-model="user.imagePreviewSize">
                            <option v-for="imageSize in imagePreviewSizes" :key="imageSize"
                                    :value="imageSize">
                                {{ imageSize }}
                            </option>
                        </select>
                    </div>

                    <div class="col-span-6 xl:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">Institution
                            <a href="#"
                               class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                               data-bs-toggle="tooltip"
                               title="Der Nutzer muss einer Institution zugehören, um angelegt werden zu können.">*</a>
                        </label>
                        <VueMultiselect v-model="user.institution"
                                        :options="institutions"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-labels="true"
                                        :searchable="searchable"
                                        @search-change="searchInstitution"
                                        placeholder="Select one"
                                        label="name"
                                        track-by="id"
                                        open-direction="bottom"
                                        noOptions="Type to search"
                        />
                    </div>
                    <div class="col-span-6 xl:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">Rollen
                            <a href="#"
                               class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                               data-bs-toggle="tooltip"
                               title="Dem Nutzer muss mindestens eine Rolle zugewiesen bekommen, um angelegt werden zu können.">*
                            </a>
                        </label>
                        <VueMultiselect v-model="user.roles"
                                        :options="roles"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-labels="true"
                                        :searchable="searchable"
                                        :multiple="true"
                                        @search-change="searchRoles"
                                        placeholder="Select Roles"
                                        label="authority"
                                        track-by="id"
                                        open-direction="bottom"
                                        noOptions="Type to search"
                        />
                    </div>
                    <div class="col-span-6 xl:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">Gruppen</label>
                        <VueMultiselect v-model="user.groups"
                                        :options="groups"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-labels="true"
                                        :searchable="searchable"
                                        :multiple="true"
                                        @search-change="searchGroup"
                                        placeholder="Select Groups"
                                        label="name"
                                        track-by="id"
                                        open-direction="bottom"
                                        noOptions="Type to search"
                        />
                    </div>
                </div>
            </div>

            <div class="flex justify-between bg-gray-50">
                <div class="px-4 py-3 bg-gray-50 sm:px-6">
                    <button v-if="user.id" @click="generateApiKey()" type="button"
                            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        API Key generieren
                    </button>
                </div>

                <div class="px-4 py-3 bg-gray-50 sm:px-6">
                    <button v-if="!user.id" @click="create()" type="button"
                            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Nutzer erstellen
                    </button>

                    <button v-if="user.id" @click="update()" type="button"
                            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Nutzer aktualisieren
                    </button>
                </div>
            </div>
        </div>
    </div>

    <h1 v-if="user.id">Nutzerberechtigungen</h1>

    <div v-if="user.id" class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Typ
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Für
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
                        <tr v-for="permission in user.permissions" :key="permission.id">
                            <td class="px-4 py-4 whitespace-nowrap">{{ permission.type }}</td>
                            <td v-if="permission.targetGroup" class="px-4 py-4 whitespace-nowrap">Group
                                {{ permission.targetGroup.name }}
                            </td>
                            <td v-if="permission.targetInstitution" class="px-4 py-4 whitespace-nowrap">Institution
                                {{ permission.targetInstitution.name }}
                            </td>
                            <td v-if="permission.targetScanner" class="px-4 py-4 whitespace-nowrap">Scanner
                                {{ permission.targetScanner.name }}
                            </td>
                            <td v-if="permission.targetId" class="px-4 py-4 whitespace-nowrap">JDX-Client ID
                                {{ permission.targetId }}
                            </td>
                            <td v-if="permission.targetString" class="px-4 py-4 whitespace-nowrap">JobType
                                {{ permission.targetString }}
                            </td>
                            <td>
                                <button @click="deletePermission(permission.id)" type="button"
                                        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Entfernen
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <h1 v-if="user.id">Neue Berechtigung</h1>

    <div v-if="user.id" class="mt-5 md:mt-0 md:col-span-2">
        <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
                <div class="flex flex-row">
                    <div class="w-2/5">
                        <select v-model="selectedPermissionType" @change="resetSelectedTargetType()">
                            <option v-for="permissionType in permissionTypes" :key="permissionType.name"
                                    :value="permissionType.name">
                                {{ permissionType.description }}
                            </option>
                        </select>
                    </div>
                    <div class="w-1/5">
                        <select v-if="selectedPermissionType" v-model="selectedTargetType"
                                @change="resetSelectedTarget()">
                            <option
                                    v-for="target in permissionTypes.find(permissionType => permissionType.name === selectedPermissionType).targets.split(', ')"
                                    :key="target">
                                {{ target }}
                            </option>
                        </select>
                    </div>
                    <div class="w-1/5">
                        <VueMultiselect v-if="selectedTargetType === 'Institution'" v-model="selectedTarget"
                                        :options="institutions"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-labels="true"
                                        :searchable="searchable"
                                        @search-change="searchInstitution"
                                        placeholder="Select one"
                                        label="name"
                                        track-by="id"
                                        open-direction="bottom"
                                        noOptions="Type to search"
                        />
                        <VueMultiselect v-if="selectedTargetType === 'Group'" v-model="selectedTarget"
                                        :options="groups"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-labels="true"
                                        :searchable="searchable"
                                        @search-change="searchGroup"
                                        placeholder="Select one"
                                        label="name"
                                        track-by="id"
                                        open-direction="bottom"
                                        noOptions="Type to search"
                        />
                        <VueMultiselect v-if="selectedTargetType === 'Scanner'" v-model="selectedTarget"
                                        :options="scanner"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-labels="true"
                                        :searchable="searchable"
                                        @search-change="searchScanner"
                                        placeholder="Select one"
                                        label="name"
                                        track-by="id"
                                        open-direction="bottom"
                                        noOptions="Type to search"
                        />
                        <input v-if="selectedTargetType === 'ID'" v-model="selectedTarget" type="text" name="targetId" id="targetId"
                               class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                        <input v-if="selectedTargetType === 'String'" v-model="selectedTarget" type="text" name="targetString" id="targetString"
                               class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <div class="w-1/5">
                        <button @click="addPermission()" type="button"
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right">
                            Hinzufügen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h1 v-if="user.id">Export Definitionen</h1>

    <div v-if="user.id" class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Typ
                            </th>
                            <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Für
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
                        <tr v-for="exportDefinition in user.exportDefinitions" :key="exportDefinition">
                            <td class="px-4 py-4 whitespace-nowrap">{{ exportDefinition.type }}</td>
                            <td v-if="exportDefinitionTypes" class="px-4 py-4 whitespace-nowrap">
                                {{ exportDefinition.url }}
                            </td>
                            <td>
                                <button @click="deleteExportDefinition(exportDefinition.id)" type="button"
                                        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Entfernen
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <h1 v-if="user.id">Neue Export Definition</h1>

    <div v-if="user.id" class="mt-5 md:mt-0 md:col-span-2">
        <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
                <div class="flex flex-row">
                    <div class="w-2/5">
                        <select v-model="selectedExportDefinitionType" @change="resetSelectedTargetType()">
                            <option v-for="exportDefinitionType in exportDefinitionTypes" :key="exportDefinitionType"
                                    :value="exportDefinitionType">
                                {{ exportDefinitionType }}
                            </option>
                        </select>
                    </div>
                    <div class="w-2/5">
                        <input v-model="exportDefinitionUrl" type="text" name="exportURL" id="exportURL"
                               class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="w-1/5">
                        <button @click="addExportDefinition()" type="button"
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right">
                            Hinzufügen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div  v-show="showApiKey" tabindex="-1" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-hidden="true" id="apiModal">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">

                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">API Key</h3>
                            <div class="mt-2">
                                <br>
                                <p class="text-sm text-gray-500">Der folgende API Key wurde generiert. Nach schließen des Fensters kann dieser nicht erneut angezeigt werden!</p>
                            </div>
                            <br>
                            <textarea v-model="apiKey" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-white focus:bg-gray-700 focus:border-indigo-500 focus:outline-none shadow-sm"
                                      id="annotation"
                                      rows="3"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button  @click="showApiKey = false" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">Schließen</button>
                    <button  @click="copyApiKeyToClipboard" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Kopieren</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "User",
    data() {
        return {
            user: {
                id: null,
                username: "",
                name: "",
                signerName: "",
                password: "",
                enabled: undefined,
                accountExpired: undefined,
                accountLocked: undefined,
                passwordExpired: undefined,
                importTechnology: undefined,
                importResults: undefined,
                importEvaluation: undefined,
                imagePreviewType: null,
                imagePreviewSize: null,

                institution: {},
                groups: [],
                permissions: [],
                exportDefinitions: [],
                medicalReportButtons: [],
                roles: []
            },
            roles: [],
            institutions: [],
            groups: [],
            permissionTypes: [],
            imagePreviewTypes: [],
            imagePreviewSizes: [],
            scanner: [],
            selectedPermissionType: null,
            selectedTargetType: null,
            selectedTarget: null,

            exportDefinitionTypes: [],
            selectedExportDefinitionType: null,
            exportDefinitionUrl: null,

            selected: null,
            apiKey: null,
            showApiKey: false
        }
    },
    methods: {
        searchRoles(query) {
            this.$http
                .get('/api/v1/role/search?query=' + query)
                .then(response => {
                    console.log("Roles:");
                    console.log(response);
                    this.roles = response.data;
                    console.log(this.roles)
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden der Rollen ist ein Fehler aufgetreten!")
                })
        },
        searchInstitution(query) {
            this.$http
                .get('/api/v1/institution/search?query=' + query)
                .then(response => {
                    console.log("Institutions:");
                    console.log(response);
                    this.institutions = response.data;
                    console.log(this.institutions)
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden der Institution ist ein Fehler aufgetreten!")
                })
        },
        searchGroup(query) {
            this.$http
                .get('/api/v1/group/search?query=' + query)
                .then(response => {
                    console.log("Group:");
                    console.log(response);
                    this.groups = response.data;
                    console.log(this.groups)
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden der Gruppen ist ein Fehler aufgetreten!")
                })
        },
        searchScanner(query) {
            this.$http
                .get('/api/v1/scanner/search?query=' + query)
                .then(response => {
                    console.log("Scanner:");
                    console.log(response);
                    this.scanner = response.data;
                    console.log(this.scanner)
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden des Scanners ist ein fehler aufgetreten!")
                })
        },
        create() {
            console.log(this.$http)
            console.log(this.user.institution)
            console.log(this.user)
            if (!this.user.institution.id) {
                alert("User needs to belong to an institution")
                return
            }
            this.$http
                .post('/api/v1/user/', this.user)
                .then(response => {
                    if (response.data.id) {
                        alert("Nutzer erfolgreich angelegt. ID: " + response.data.id);
                        this.$router.push({name: 'settings', params: {currentTab: 'user', id: response.data.id}})
                    } else {
                        alert("Beim Speichern des Nutzers ist ein Fehler aufgetreten!")
                    }
                    this.user = response.data
                })
                .catch(reason => {
                    if (reason.response.request.response) {
                        let message = JSON.parse(reason.response.request.response)._embedded.errors[0].message
                        if (message.startsWith("user.name")) {
                            alert(message.replace("user.name:", "Name"))
                        } else if (message.startsWith("user.password")) {
                            alert(message.replace("user.password: ", ""))
                        } else if (message.startsWith("user.username")) {
                            alert(message.replace("user.username:", "Username"))
                        } else {
                            alert("Uncaught error occurred: " + message)
                        }
                    }
                    console.log(JSON.parse(reason.response.request.response)._embedded)
                })
        },
        update() {
            console.log(this.user)
            let data = JSON.parse(JSON.stringify(this.user))
            delete data.permissions
            this.$http
                .put('/api/v1/user/' + this.user.id, data)
                .then(response => {
                    console.log(response)
                    this.user = response.data
                    alert("Nutzer wurde erfolgreich aktualisiert")
                    this.loadUser()
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Aktualisieren des Nutzers ist ein Fehler aufgetreten")
                })

        },
        loadUser() {
            if (!this.$route.params.id) return;
            this.$http
                .get('/api/v1/user/full/' + this.$route.params.id)
                .then(response => {
                    console.log("User:")
                    console.log(response)
                    this.user = response.data
                    if (!this.user.permissions) {
                        this.user.permissions = []
                    } else {
                        this.user.permissions.sort((a, b) => {
                            return a.id - b.id
                        })
                    }
                    if (!this.user.exportDefinitions) {
                        this.user.exportDefinitions = []
                    } else {
                        this.user.exportDefinitions.sort((a, b) => {
                            return a.id - b.id
                        })
                    }
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Der Nutzer konnte nicht geladen werden!")
                })
        },
        loadPermissionTypes() {
            this.$http
                .get('/api/v1/permission/types/')
                .then(response => {
                    console.log("Berechtigungs-Typ:");
                    console.log(response);
                    this.permissionTypes = response.data
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden des Berechtigung-Typs ist ein Fehler aufgetreten!")
                })
        },
        loadExportDefinitionTypes() {
            this.$http
                .get('/api/v1/exportDefinition/types/')
                .then(response => {
                    console.log("ExportDefinitions-Typ:");
                    console.log(response);
                    this.exportDefinitionTypes = response.data
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden des ExportDefinition-Typs ist ein Fehler aufgetreten!")
                })
        },
        loadImagePreviewTypes() {
            this.$http
                .get('/api/v1/report/imagePreviewTypes/')
                .then(response => {
                    this.imagePreviewTypes = response.data
                    this.user.imagePreviewType = this.imagePreviewTypes[0]
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden der ImagePreviewTypes ist ein Fehler aufgetreten!")
                })
        },
        loadImagePreviewSizes() {
            this.$http
                .get('/api/v1/report/imagePreviewSizes/')
                .then(response => {
                    this.imagePreviewSizes = response.data
                    this.user.imagePreviewSize = this.imagePreviewSizes[this.imagePreviewSizes.length - 1]
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Laden der ImagePreviewSizes ist ein Fehler aufgetreten!")
                })
        },
        resetSelectedTargetType() {
            this.selectedTargetType = null
        },
        resetSelectedTarget() {
            this.selectedTarget = null
        },
        addPermission() {
            let data = {
                'user': this.user,
                'type': this.selectedPermissionType,
                'targetInstitution': this.selectedTargetType === 'Institution' ? this.selectedTarget : null,
                'targetGroup': this.selectedTargetType === 'Group' ? this.selectedTarget : null,
                'targetScanner': this.selectedTargetType === 'Scanner' ? this.selectedTarget : null,
                'targetId': this.selectedTargetType === 'ID' ? this.selectedTarget : null,
                'targetString': this.selectedTargetType === 'String' ? this.selectedTarget : null
            }
            this.$http
                .post('/api/v1/permission/', data)
                .then(response => {
                    console.log("Erstellste Berechtigung:")
                    console.log(response)
                    this.user.permissions.push(response.data)
                    alert("Berechtigung wurde erfolgreich hinzugefügt!")
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Permission could not be created!")
                })
        },
        addExportDefinition() {
            let data = {
                'user': this.user,
                'type': this.selectedExportDefinitionType,
                'url': this.exportDefinitionUrl,
            }
            this.$http
                .post('/api/v1/exportDefinition/', data)
                .then(response => {
                    console.log("Erstellte ExportDefinition:")
                    console.log(response)
                    this.user.exportDefinitions.push(response.data)
                    alert("ExportDefinition wurde erfolgreich hinzugefügt!")
                })
                .catch(reason => {
                    console.log(reason)
                    alert("ExportDefiniton could not be created!")
                })
        },
        deletePermission(id) {
            this.$http
                .delete('/api/v1/permission/' + id)
                .then(response => {
                    console.log("Deleted permission.")
                    console.log(response)
                    this.user.permissions = this.user.permissions.filter(permission => permission.id !== id)
                    alert("Berechtigung wurde erfolgreich gelöscht!")
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Löschen der Berechtigung ist ein Fehler aufgetreten. Die Berechtigung konnte nicht gelöscht werden!")
                })
        },
        deleteExportDefinition(id) {
            this.$http
                .delete('/api/v1/exportDefinition/' + id)
                .then(response => {
                    console.log("Deleted export Definition.")
                    console.log(response)
                    this.user.exportDefinitions = this.user.exportDefinitions.filter(exportDefinition => exportDefinition.id !== id)
                    alert("Export Definition wurde erfolgreich gelöscht!")
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim Löschen der Export Definition ist ein Fehler aufgetreten. Die Export Definition konnte nicht gelöscht werden!")
                })
        },
        generateApiKey() {
            this.$http
                .put('/api/v1/user/api/' + this.user.id)
                .then(response => {
                    console.log("Generated Api Key.")
                    console.log(response.data)
                    this.apiKey = response.data
                    this.showApiKey = true
                })
                .catch(reason => {
                    console.log(reason)
                    alert("Beim generieren des API Keys ist ein Fehler aufgetreten.")
                })
        },
        copyApiKeyToClipboard() {
            navigator.clipboard.writeText(this.apiKey)
        }
    },
    mounted() {
        this.loadPermissionTypes();
        this.loadExportDefinitionTypes();
        this.loadImagePreviewTypes();
        this.loadImagePreviewSizes();
        this.loadUser();
    }
}
</script>

<style scoped>

</style>