<template>

  <div>
    <h1>Remote Dicom Devices</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Beschreibung
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Host
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Find
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Move
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Store
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Aktiv
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
              <tr v-for="device in remoteDicomDevices" :key="device.id">
                <td class="px-4 py-4 whitespace-nowrap">{{device.name}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{device.description}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{device.host}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{device.findScpPort}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{device.moveScpPort}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{device.storeScpPort}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{device.enabled}}</td>
                <td class="px-4 py-4 whitespace-nowrap"> <input @click="deleteDevice(device.id)" type="button" value="Delete"/></td>
              </tr>
              <tr>
                <td colspan="8" class="px-4 py-4 whitespace-nowrap">
                  <router-link :to="{ name: 'settings', params: { currentTab: 'remoteDicomDevice' }}">
                    <b> + </b> Add Remote Dicom Device
                  </router-link>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <loading-indicator :loading="loading" message="Loading dicom devices..."></loading-indicator>

</template>

<script>

import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "RemoteDicomDeviceList",
  components: {LoadingIndicator},
  data() {
    return {
      loading: true,
      remoteDicomDevices: []
    }
  },
  methods: {
    deleteDevice(id) {
      this.$http
          .delete('/api/v1/configuration/dicomDevice/remoteDevice/' + id)
          .then(response => {
            console.log(response);
            this.$router.go(0);
          })
          .catch(reason => {
            console.log(reason)
            alert("Couldn't be deleted.")
          })
    },
    toNewDevice() {
      this.router.push({ name: 'settings', params: { currentTab: 'remoteDicomDevice' } });
    }
  },
  mounted() {
    this.loading = true;
    this.$http
        .get('/api/v1/configuration/dicomDevice/remoteDevice')
        .then(response => {
          this.report = response.data
          console.log(response.data)
          this.remoteDicomDevices = response.data
          this.loading = false;
        }).catch(reason => {
      if (reason.status === 401) {
        // handled by axios interceptor
      } else {
        console.log(reason)
        alert("Die Verbindung zum Server ist fehlgeschlagen. Bitte versuchen Sie es erneut.")
        this.loading = false;
      }
    });
  }
}
</script>

<style scoped>

</style>