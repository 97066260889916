<template>

  <div>
    <h1>Remote Dicom Device</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-6 gap-6">

            <div class="col-span-6 sm:col-span-6">
              <label for="deviceName" class="block text-sm font-medium text-gray-700">Device name</label>
              <input v-model="device.name" type="text" name="deviceName" id="deviceName" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="description" class="block text-sm font-medium text-gray-700">Device description</label>
              <textarea v-model="device.description" name="description" id="description" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="aet" class="block text-sm font-medium text-gray-700">AE Title</label>
              <input v-model="device.aet" type="text" name="aet" id="aet" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="host" class="block text-sm font-medium text-gray-700">Host address</label>
              <input v-model="device.host" type="text" name="host" id="host" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="queryEnabled" class="inline text-sm font-medium text-gray-700">Query this device</label>
              <input v-model="device.queryEnabled" type="checkbox" name="queryEnabled" id="queryEnabled" class="inline m-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div id="queryParameter" class="col-span-6 sm:col-span-6">
              <div class="col-span-6 sm:col-span-6">
                <label for="findScpPort" class="block text-sm font-medium text-gray-700">Find SCP Port</label>
                <input v-model="device.findScpPort" type="number" name="findScpPort" id="findScpPort" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>

              <div class="col-span-6 sm:col-span-6">
                <label for="moveScpPort" class="block text-sm font-medium text-gray-700">Move SCP Port</label>
                <input v-model="device.moveScpPort" type="number" name="moveScpPort" id="moveScpPort" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="storeEnabled" class="inline text-sm font-medium text-gray-700">Store on this device</label>
              <input v-model="device.storeEnabled" type="checkbox" name="storeEnabled" id="storeEnabled" class="inline m-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div id="storeParameter" class="col-span-6 sm:col-span-6">
              <div class="col-span-6 sm:col-span-6">
                <label for="storeScpPort" class="block text-sm font-medium text-gray-700">Store address</label>
                <input v-model="device.storeScpPort" type="number" name="storeScpPort" id="storeScpPort" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>

          </div>
        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button @click="save()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
  </div>

</template>

<script>
export default {
  name: "RemoteDicomDevice",
  data() {
    return {
      device: {
        host: "",
        aet: "",
        name: "",
        description: "",
        enabled: true,
        queryEnabled: true,
        findScpPort: -1,
        moveScpPort: -1,
        storeEnabled: false,
        storeScpPort: -1
      }
    }
  },
  methods: {
    validFormInputs() {
      let hostAetNameValid = this.device.host !== undefined && this.device.host.length > 0 &&
          this.device.aet !== undefined && this.device.aet.length > 0 &&
          this.device.name !== undefined && this.device.name.length > 0;

      let queryValid = this.device.queryEnabled === false ||
          (this.device.findScpPort > 0 && this.device.findScpPort < 65536 &&
          this.device.moveScpPort > 0 && this.device.moveScpPort < 65536);

      let storeValid = this.device.storeEnabled === false ||
          (this.device.storeEnabled > 0 && this.device.storeEnabled < 65536);

      return hostAetNameValid && queryValid && storeValid;
    },
    save() {
      if (this.validFormInputs()) {
        this.device["@class"] = "de.jungdiagnostics.repository.communication.dicom.RemoteDicomDevice"
        this.$http
            .put('/api/v1/configuration/dicomDevice/remoteDevice', this.device)
            .then(response => {
              console.log(response);
              this.$router.push({name: 'settings', params: {currentTab: 'remoteDicomDevices'}})
            })
            .catch(reason => {
              console.log(reason)
              this.savingDraft = false
              alert("Error saving draft!")
            })
      } else {
        alert("Please check your inputs.");
      }
    }
  }
}
</script>

<style scoped>

</style>