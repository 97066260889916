<template>

  <div>
    <h1>Dicom Einstellungen</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Funktion
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  AET
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Host
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Port
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Proxy
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200" v-if="configuration">
              <DicomSettingsRow role="Local FindSCU" :givenConfig="configuration.localFindScu"></DicomSettingsRow>
              <DicomSettingsRow role="Local MoveSCU" :givenConfig="configuration.localMoveScu"></DicomSettingsRow>
              <DicomSettingsRow role="Local StoreSCU" :givenConfig="configuration.localStoreScu"></DicomSettingsRow>
              <DicomSettingsRow role="Local StoreSCP" :givenConfig="configuration.localStoreScp"></DicomSettingsRow>
              <DicomSettingsRow role="Remote FindSCP" :givenConfig="configuration.remoteFindScp"></DicomSettingsRow>
              <DicomSettingsRow role="Remote MoveSCP" :givenConfig="configuration.remoteMoveScp"></DicomSettingsRow>
              <DicomSettingsRow role="Remote StoreSCP" :givenConfig="configuration.remoteStoreScp"></DicomSettingsRow>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import DicomSettingsRow from "@/components/settings/DicomSettingsRow";
export default {
  name: "DicomSettings",
  components: {DicomSettingsRow},
  data() {
    return {
      configuration: undefined
    }
  },
  mounted() {
    this.$http.get("/api/v1/configuration").then(value => {
      this.configuration = value.data
      console.log(value)
    }).catch(reason => {
      console.log(reason)
    })
  }
}
</script>

<style scoped>
  h1{
    margin: 0.5rem auto;
    text-align: left;
    font-weight: bold;
  }
</style>