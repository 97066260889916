import axios from 'axios'

<template>
  <div class="flex-container">
    <div class="sidebar" style="width: 20%">
      <div class="category">
        <div class="category-name">Einstellungen</div>
        <div class="option-item" @click="changeTab('userSettings')">Nutzerinformationen</div>
        <div class="option-item" @click="changeTab('reportSettings')">Report Einstellungen</div>
        <div class="option-item" @click="changeTab('medicalReportButtons')">Befund Buttons</div>
        <div class="option-item" @click="changeTab('changePassword')">Passwort ändern</div>
      </div>
<!--      <div class="category">-->
<!--        <div class="category-name">Templates</div>-->
<!--        <div class="option-item">Report</div>-->
<!--        <div class="option-item">Text Templates</div>-->
<!--      </div>-->

      <div class="category" v-show="currentUserIsSystemAdmin">
        <div class="category-name mt-6">Root-Administration</div>
        <div class="category">
          <div class="category-name">Verwaltung</div>
          <div class="option-item" @click="changeTab('userList')">Nutzer</div>
          <div class="option-item" @click="changeTab('scannerList')">Scanner</div>
          <div class="option-item" @click="changeTab('groupList')">Gruppen</div>
          <div class="option-item" @click="changeTab('institutionList')">Institutionen</div>
        </div>
        <div class="category">
          <div class="category-name">Arbeitsablauf</div>
          <div class="option-item" @click="changeTab('workflow')">Workflow</div>
        </div>
        <div class="category">
          <div class="category-name">Dicom Verbindungen und REST</div>
          <div class="option-item" @click="changeTab('remoteDicomDevices')">Remote Dicom Devices</div>
          <div class="option-item" @click="changeTab('localDicomDevice')">Local Dicom Device</div>
          <div class="option-item" @click="changeTab('restInterfaces')">Rest Interfaces</div>
          <div class="option-item" @click="changeTab('jobManager')">JobManager</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div>
        <component v-bind:is="currentTab"></component>
      </div>
    </div>
  </div>

</template>

<script>
import DicomSettings from "@/components/settings/DicomSettings";
import AddressSettings from "@/components/settings/AddressSettings";
import AppearanceSettings from "@/components/settings/AppearanceSettings";
import InputNodeSettings from "@/components/settings/InputNodeSettings";
import OutputNodeSettings from "@/components/settings/OutputNodeSettings";
import RemoteDicomDeviceList from "@/components/settings/RemoteDicomDeviceList";
import RemoteDicomDevice from "@/components/settings/RemoteDicomDevice";
import LocalDicomDevice from "@/components/settings/LocalDicomDevice";
import Workflow from "@/components/settings/Workflow";
import RestInterfaceList from "@/components/settings/RestInterfaceList";
import RestInterface from "@/components/settings/RestInterface";
import UserSettings from "@/components/settings/UserSettings";
import ChangePassword from "@/components/settings/ChangePassword";
import ReportSettings from "@/components/settings/Report Settings";
import User from "@/components/settings/User";
import UserList from "@/components/settings/UserList";
import ScannerList from "@/components/settings/ScannerList";
import InstitutionList from "@/components/settings/InstitutionList";
import Institution from "@/components/settings/Institution";
import GroupList from "@/components/settings/GroupList";
import Scanner from "@/components/settings/Scanner";
import Group from "@/components/settings/Group";
import MedicalReportButtons from "@/components/settings/MedicalReportButtons";
import MedicalReportButtonsCreate from "@/components/settings/MedicalReportButtonsCreate";
import {shallowRef} from "vue";
import jobManager from "@/components/settings/JobManager";


const settings = {
  dicom: shallowRef(DicomSettings),
  jobManager: shallowRef(jobManager),
  remoteDicomDevices: shallowRef(RemoteDicomDeviceList),
  remoteDicomDevice: shallowRef(RemoteDicomDevice),
  localDicomDevice: shallowRef(LocalDicomDevice),
  inputNodeSettings: shallowRef(InputNodeSettings),
  outputNodeSettings: shallowRef(OutputNodeSettings),
  restInterfaces: shallowRef(RestInterfaceList),
  restInterface: shallowRef(RestInterface),
  userSettings: shallowRef(UserSettings),
  changePassword: shallowRef(ChangePassword),
  reportSettings: shallowRef(ReportSettings),
  medicalReportButtons:shallowRef(MedicalReportButtons),
  medicalReportButtonsCreate:shallowRef(MedicalReportButtonsCreate),
  user: shallowRef(User),
  userList: shallowRef(UserList),
  workflow: shallowRef(Workflow),
  scannerList: shallowRef(ScannerList),
  scanner: shallowRef(Scanner),
  institutionList: shallowRef(InstitutionList),
  institution: shallowRef(Institution),
  groupList: shallowRef(GroupList),
  group: shallowRef(Group)
}

export default {
  name: "settings.vue",
  components: {AddressSettings, DicomSettings, AppearanceSettings},
  data: function () {
    return {
      currentTab: undefined
    }
  },
  computed: {
    currentUserIsSystemAdmin() {
      return (this.$store.state.user.roles.indexOf("ROLE_SYSTEM_ADMIN") !== -1)
    }
  },
  mounted () {
    if (this.currentTab === undefined) {
      if (this.$route.params.currentTab !== undefined) {
        let tab = settings[this.$route.params.currentTab]
        if (tab !== undefined) {
          this.currentTab = tab
          return
        }
      }
      this.currentTab = settings.userSettings
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          console.log(previousParams !== undefined);
          if (toParams.currentTab) {
            this.currentTab = settings[toParams.currentTab];
          }
        }
    )
  },
  methods: {
    changeTab(name) {
      this.$router.push({ name: 'settings', params: { currentTab: name } });
    }
  }
}
</script>

<style>
  h1{
    margin: 0.5rem auto;
    text-align: left;
    font-weight: bold;
  }
</style>

<style scoped>

  .flex-container{
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .sidebar {
    /*background: lightskyblue;*/
    float: left;
    /*flex-grow: 2;*/
    min-width: 12rem;
    height: 100%;
    padding: 1rem;
  }

  .content {
    /*background-color: orange;*/
    float: left;
    flex-grow: 8;
    height: 100%;
    padding: 1rem;
    border-left: 1px solid lightgrey;
  }

  .category{
    margin: 0.5rem auto;
  }

  .category-name{
    font-weight: bold;
  }

  .option-item {
    margin: 0.05rem auto;
    cursor: pointer;
    width: 100%;
  }

  .option-item:hover {
    color: lightgrey;
    margin: 0.05rem auto;
    cursor: pointer;
  }

</style>