<template>

  <h1>Institution</h1>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700">
              <b>Name</b><br>
              Name
            </label>
            <input v-model="institution.name" type="text" name="name" id="name"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button v-if="!institution.id" @click="create()" type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Institution erstellen
          </button>
          <button v-if="institution.id" @click="update()" type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Institution aktualisieren
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Institution",
  data() {
    return {
      institution: {
        id: null,
        name: ""
      }
    }
  },
  methods: {
    create() {
      this.$http
          .post('/api/v1/institution/', this.institution)
          .then(response => {
            //console.log(response)
            console.log("hi"+ response.data.id)
            console.log("Error is:" + response)

            if (response.data.id) {

              alert("Institution created successfully. ID: " + response.data.id)
              this.institution = response.data
              this.$router.push({name: 'settings', params: {currentTab: 'institution', id: response.data.id}})
            } else {

              alert("Beim Specihern der Institution ist ein Fehler aufgetreten.")
            }
          })
          .catch(reason => {
            console.log(JSON.parse(reason.response.request.response)._embedded)
            if(reason.response.request.response){
              let message = JSON.parse(reason.response.request.response)._embedded.errors[0].message
              if(message.startsWith("institution.name")){
                alert(message.replace("institution.name:", "Name"))
              }
              else{
                alert("Uncaught error occurred: " + message)
              }
            }
          })
    },
    update() {
      console.log(this.institution)
      this.$http
          .post('/api/v1/institution/' + this.institution.id, this.institution)
          .then(response => {
            console.log(response)
            this.institution = response.data
            alert("Institution wurde erfolgreich aktualisiert. ID: " + response.data.id)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Aktualisieren der Institution ist ein Fehler aufgetreten!")
          })
    },
    loadInstitution() {
      if (!this.$route.params.id) return;
      this.$http
          .get('/api/v1/institution/' + this.$route.params.id)
          .then(response => {
            console.log(response)
            this.institution = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der Institution ist ein Fehler aufgetreten!")
          })
    }
  },
  mounted() {
    this.loadInstitution();
  }
}
</script>

<style scoped>

</style>