<template>

  <div>
    <h1>Rest Interfaces</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Beschreibung
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Host
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Service Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Is Source
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Is Destination
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Aktiv
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
              <tr v-for="restInterface in restInterfaces" :key="restInterface.id">
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.name}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.description}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.url}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.className}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.isSource}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.isDestination}}</td>
                <td class="px-4 py-4 whitespace-nowrap">{{restInterface.enabled}}</td>
                <td class="px-4 py-4 whitespace-nowrap"> <input @click="deleteRestInterface(restInterface.id)" type="button" value="Delete"/></td>
              </tr>
              <tr>
                <td colspan="8" class="px-4 py-4 whitespace-nowrap">
                  <router-link :to="{ name: 'settings', params: { currentTab: 'restInterface' }}">
                    <b> + </b> Add REST Service
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <loading-indicator :loading="loading" message="Loading dicom devices..."></loading-indicator>

</template>

<script>

import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "RestInterfaceList",
  components: {LoadingIndicator},
  data() {
    return {
      loading: true,
      restInterfaces: []
    }
  },
  methods: {
    deleteRestInterface(id) {
      this.$http
          .delete('/api/v1/configuration/rest/interface/' + id)
          .then(response => {
            console.log(response);
            this.$router.go(0);
          })
          .catch(reason => {
            console.log(reason)
            alert("Couldn't be deleted.")
          })
    },
    toNewRestInterface() {
      this.router.push({ name: 'settings', params: { currentTab: 'restInterface' } });
    }
  },
  mounted() {
    this.loading = true;
    this.$http
        .get('/api/v1/configuration/rest/interface')
        .then(response => {
          this.report = response.data
          console.log(response.data)
          this.restInterfaces = response.data
          this.loading = false;
        }).catch(reason => {
      if (reason.status === 401) {
        // handled by axios interceptor
      } else {
        console.log(reason)
        alert("Die Verbindung zum Server ist fehlgeschlagen. Bitte versuchen Sie es erneut.")
        this.loading = false;
      }
    });
  }
}
</script>

<style scoped>

</style>