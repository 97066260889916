<template>

  <div>
    <h1>Data Origins</h1>
  </div>

</template>

<script>
export default {
  name: "InputNodeSettings"
}
</script>

<style scoped>

</style>