<template>
  <tr :class="{ altered: changed }" v-show="!loading">
    <td class="px-4 py-4 whitespace-nowrap dark:text-gray-900">
      {{ role }}
    </td>
    <td class="px-4 py-4 whitespace-nowrap">
      <input type="text" name="aet" autocomplete="" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="config.aet">
    </td>
    <td class="px-4 py-4 whitespace-nowrap">
      <input type="text" name="host" autocomplete="" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="config.host">
    </td>
    <td class="px-4 py-4">
      <input type="number" name="port" autocomplete="" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="config.port">
    </td>
    <td class="px-4 py-4">
      <input type="text" name="proxy" autocomplete="" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="config.proxy">
    </td>
    <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
      <button class="text-indigo-600 hover:text-indigo-900" @click="save">Speichern</button>
    </td>
  </tr>
  <tr v-show="loading">
    <td class="px-4 py-4 whitespace-nowrap">
      {{ role }}
    </td>
    <td class="px-4 py-4 whitespace-nowrap">
      ...
    </td>
    <td class="px-4 py-4 whitespace-nowrap">
      ...
    </td>
    <td class="px-4 py-4">
      ...
    </td>
    <td class="px-4 py-4">
      ...
    </td>
    <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
      ...
    </td>
  </tr>
</template>

<script>
export default {
  name: "DicomSettingsRow",
  props: ['role', 'givenConfig'],
  data() {
    return {
      config: {},
      beforeChanges: {},
      loading: false
    }
  },
  computed: {
    changed() {
      // console.log(this.config)
      // console.log(this.beforeChanges)

      return !(this.config.aet == this.beforeChanges.aet &&
          this.config.host == this.beforeChanges.host &&
          this.config.port == this.beforeChanges.port &&
          this.config.proxy == this.beforeChanges.proxy)
    }
  },
  mounted() {
    this.beforeChanges = {
      aet: this.givenConfig.aet,
      host: this.givenConfig.host,
      port: this.givenConfig.port,
      proxy: this.givenConfig.proxy,
    }
    this.config = this.givenConfig
  },
  methods: {
    save() {
      this.loading = true
      this.$http.put("api/v1/configuration/save/dicomConfiguration/" + this.config.id, this.config).then(() => {
        this.beforeChanges.aet = this.config.aet
        this.beforeChanges.host = this.config.host
        this.beforeChanges.port = this.config.port
        this.beforeChanges.proxy = this.config.proxy
        this.loading = false
      }).catch(function() {
        alert("Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.")
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  .altered{
    background-color: lightyellow;
  }
</style>