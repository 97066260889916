<template>

  <h1>Gruppe</h1>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input v-model="group.name" type="text" name="name" id="name"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

          <div class="col-span-6 xl:col-span-6">
            <label class="block text-sm font-medium text-gray-700">Institution</label>
            <VueMultiselect  v-model="group.institution"
                             :options="institutions"
                             :close-on-select="true"
                             :clear-on-select="true"
                             :show-labels="true"
                             :searchable="searchable"
                             @search-change="searchInstitution"
                             placeholder="Select one"
                             label="name"
                             track-by="id"
                             open-direction="bottom"
                             noOptions="Type to search"
            />
          </div>
        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button v-if="!group.id" @click="create()" type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Gruppe erstellen
          </button>
          <button v-if="group.id" @click="update()" type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Gruppe aktualisieren
          </button>
        </div>
      </div>
    </div>
  </div>

  <h1 v-if="group.id">Gruppen Berechtigungen</h1>

  <div v-if="group.id" class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                For
              </th>
              <th>
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
            <tr v-for="permission in group.permissions" :key="permission.id">
              <td class="px-4 py-4 whitespace-nowrap">{{ permission.type }}</td>
              <td v-if="permission.targetGroup" class="px-4 py-4 whitespace-nowrap">Group
                {{ permission.targetGroup.name }}
              </td>
              <td v-if="permission.targetInstitution" class="px-4 py-4 whitespace-nowrap">Institution
                {{ permission.targetInstitution.name }}
              </td>
              <td v-if="permission.targetScanner" class="px-4 py-4 whitespace-nowrap">Scanner
                {{ permission.targetScanner.name }}
              </td>
              <td>
                <button @click="deletePermission(permission.id)" type="button"
                        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Entfernen
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <h1 v-if="group.id">Berechtigung hinzufügen</h1>

  <div v-if="group.id" class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="flex flex-row">
          <div class="w-2/5">
            <select v-model="selectedPermissionType" @change="resetSelectedTargetType()">
              <option v-for="permissionType in permissionTypes" :key="permissionType.name"
                      :value="permissionType.name">
                {{ permissionType.description }}
              </option>
            </select>
          </div>
          <div class="w-1/5">
            <select v-if="selectedPermissionType" v-model="selectedTargetType" @change="resetSelectedTarget()">
              <option
                  v-for="target in permissionTypes.find(permissionType => permissionType.name === selectedPermissionType).targets.split(', ')"
                  :key="target">
                {{ target }}
              </option>
            </select>
          </div>
          <div class="w-1/5">
            <VueMultiselect v-if="selectedTargetType === 'Institution'" v-model="selectedTarget"
                            :options="institutions"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :show-labels="true"
                            :searchable="searchable"
                            @search-change="searchInstitution"
                            placeholder="Select one"
                            label="name"
                            track-by="id"
                            open-direction="bottom"
                            noOptions="Type to search"
            />
            <VueMultiselect v-if="selectedTargetType === 'Group'" v-model="selectedTarget"
                            :options="groups"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :show-labels="true"
                            :searchable="searchable"
                            @search-change="searchGroup"
                            placeholder="Select one"
                            label="name"
                            track-by="id"
                            open-direction="bottom"
                            noOptions="Type to search"
            />
            <VueMultiselect v-if="selectedTargetType === 'Scanner'" v-model="selectedTarget"
                            :options="scanner"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :show-labels="true"
                            :searchable="searchable"
                            @search-change="searchScanner"
                            placeholder="Select one"
                            label="name"
                            track-by="id"
                            open-direction="bottom"
                            noOptions="Type to search"
            />
          </div>
          <div class="w-1/5">
            <button @click="addPermission()" type="button"
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 float-right" >
              Hinzufügen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Group",
  data() {
    return {
      group: {
        id: null,
        name: "",
        institution: null,
        permissions: []
      },
      scanner: [],
      groups: [],
      institutions: [],
      permissionTypes: [],
      selectedPermissionType: null,
      selectedTargetType: null,
      selectedTarget: null
    }
  },
  methods: {
    searchInstitution(query){
      this.$http
          .get('/api/v1/institution/search?query='+query)
          .then(response => {
            console.log("Institutions:");
            console.log(response);
            this.institutions = response.data;
            console.log(this.institutions)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der Institutionen ist ein Fehler aufgetreten!")
          })
    },
    searchGroup(query){
      this.$http
          .get('/api/v1/group/search?query='+query)
          .then(response => {
            console.log("Group:");
            console.log(response);
            this.groups = response.data;
            console.log(this.groups)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der Gruppen ist ein Fehler aufgetreten!")
          })
    },
    searchScanner(query){
      this.$http
          .get('/api/v1/scanner/search?query='+query)
          .then(response => {
            console.log("Scanner:");
            console.log(response);
            this.scanner = response.data;
            console.log(this.scanner)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der Scanner ist ein Fehler aufgetreten!")
          })
    },
    create() {
      this.$http
          .post('/api/v1/group/', this.group)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              alert("Neue Gruppe wurde erfolgreich erstellt. ID: " + response.data.id)
              this.group = response.data
              this.$router.push({name: 'settings', params: {currentTab: 'group', id: response.data.id}})
            } else {
              alert("Beim Speichern der Gruppen ist ein Fehler aufgetreten.")
            }
          })
          .catch(reason => {
            console.log(reason)
            console.log(reason.response.data._embedded.errors)
            let message = JSON.parse(reason.response.request.response)._embedded.errors[0].message
            if(message.startsWith("group.name")){
              alert(message.replace("group.name:", "Name"))
            }
            else if(message.startsWith("group.institution")){
              alert(message.replace("group.institution:", "Institution"))
            }
            else{
              alert("Uncaught error occurred: "+message)
            }
          })
    },
    update() {
      console.log(this.group)
      this.$http
          .post('/api/v1/group/' + this.group.id, this.group)
          .then(response => {
            console.log(response)
            this.group = response.data
            alert("Gruppe wurde erfolgreich aktualisiert. ID: " + response.data.id)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim aktualisieren der Gruppe ist ein Fehler aufgetreten.!")
          })
    },
    loadGroup() {
      if (!this.$route.params.id) return;
      this.$http
          .get('/api/v1/group/' + this.$route.params.id)
          .then(response => {
            this.group = response.data
            if (!this.group.permissions) {
              this.group.permissions = []
            } else {
              this.group.permissions.sort((a, b) => {
                return a.id - b.id
              })
            }
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der Gruppe ist ein Fehler aufgetreten!")
          })
    },
    loadPermissionTypes() {
      this.$http
          .get('/api/v1/permission/types/')
          .then(response => {
            console.log("Permission types:");
            console.log(response);
            this.permissionTypes = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der zugehörigen Berechtigungen ist ein Fehler aufgetreten!")
          })
    },
    resetSelectedTargetType() {
      this.selectedTargetType = null
    },
    resetSelectedTarget() {
      this.selectedTarget = null
    },
    addPermission() {
      let data = {
        'group': this.group,
        'type': this.selectedPermissionType,
        'targetInstitution': this.selectedTargetType === 'Institution' ? this.selectedTarget : null,
        'targetGroup': this.selectedTargetType === 'Group' ? this.selectedTarget : null,
        'targetScanner': this.selectedTargetType === 'Scanner' ? this.selectedTarget : null
      }
      this.$http
          .post('/api/v1/permission/', data)
          .then(response => {
            console.log("Created permission:")
            console.log(response)
            this.group.permissions.push(response.data)
            alert("Neue Berechtigung wurde Grupe erfolgreich zugeteilt.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim hinzufügen einer neuen Berechtigung ist ein Fehler aufgetreten.")
          })
    },
    deletePermission(id) {
      this.$http
          .delete('/api/v1/permission/' + id)
          .then(response => {
            console.log(response)
            this.group.permissions = this.group.permissions.filter(permission => permission.id !== id)
            alert("Berechtigung wurde erfolgreich entfernt.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Entfernen der Berechtigung ist ein Fehler aufgetreten. Die Berechtigung konnte nicht gelöscht werden.")
          })
    }
  },
  mounted() {
    this.loadGroup();
    this.loadPermissionTypes();
  }
}
</script>

<style scoped>

</style>