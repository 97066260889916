<template>

  <div>
    <h1>Lokales Dicom Gerät</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <form action="#" method="POST">
      <div class="shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-6 gap-6">

            <div class="col-span-6 sm:col-span-6">
              <label for="deviceName" class="block text-sm font-medium text-gray-700">Device name</label>
              <input v-model="device.name" type="text" name="deviceName" id="deviceName" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="description" class="block text-sm font-medium text-gray-700">Device description</label>
              <textarea v-model="device.description" name="description" id="description" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="aet" class="block text-sm font-medium text-gray-700">AE Title</label>
              <input v-model="device.aet" type="text" name="aet" id="aet" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="host" class="block text-sm font-medium text-gray-700">
                Host address<br>
                <small>Leave empty for auto detection</small>
              </label>
              <input v-model="device.host" type="text" name="host" id="host" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div id="storeParameter" class="col-span-6 sm:col-span-6">
              <div class="col-span-6 sm:col-span-6">
                <label for="storeScpPort" class="block text-sm font-medium text-gray-700">Store SCP address</label>
                <input v-model="device.storeScpPort" type="number" name="storeScpPort" id="storeScpPort" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>

          </div>
        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button @click="update()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>

</template>

<script>
export default {
  name: "RemoteDicomDevice",
  data() {
    return {
      device: {
        id: undefined,
        name: "",
        description: "",
        aet: "",
        host: "",
        storeScpPort: -1
      }
    }
  },
  methods: {
    validFormInputs() {
      return this.device.aet !== undefined && this.device.aet.length > 0 &&
          this.device.name !== undefined && this.device.name.length > 0 &&
          this.device.storeScpPort > 0 && this.device.storeScpPort < 65536;
          // this.device.host !== undefined && this.device.host.length > 0 &&
    },
    update() {
      if (this.validFormInputs()) {
        this.$http
            .put('/api/v1/configuration/dicomDevice/localDevice/' + this.device.id, this.device)
            .then(response => {
              console.log(response);
              this.$router.go(0);
            })
            .catch(reason => {
              console.log(reason)
              this.savingDraft = false
              alert("Error saving draft!")
            })
      } else {
        alert("Please check your inputs.");
      }
    }
  },
  mounted() {
    console.log("mounted")
    this.$http
        .get('/api/v1/configuration/dicomDevice/localDevice/')
        .then(response => {
          console.log(response);
          this.device = response.data
        })
        .catch(reason => {
          console.log(reason)
          this.savingDraft = false
          alert("Error loading local dicom device!")
        })
  }
}
</script>

<style scoped>

</style>