<template>

  <div>
    <h1>Passwort ändern</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6">
            <label for="oldPassword" class="block text-sm font-medium text-gray-700">
              <b>Altes Passwort</b><br>
              Aktuelles Passwort zum Anmelden in ReportX.
            </label>
            <input v-model="userSettings.oldPassword" type="password" name="password" id="oldPassword"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

          <div class="col-span-6">
            <label for="newPassword" class="block text-sm font-medium text-gray-700">
              <b>Neues Passwort</b><br>
              Neues Passwort zum Anmelden in ReportX. <br>
              (Achtung: Das Passwort muss mindestens 8 Zeichen lang sein, wovon mindestens 5 Zeichen Buchstaben sein müssen. Leerzeichen sind nicht erlaubt.)
            </label>
            <input v-model="userSettings.newPassword" type="password" name="password" id="newPassword"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
            <label for="newPassword2" class="block text-sm font-medium text-gray-700">
              Passwort wiederholen.
            </label>
            <input v-model="userSettings.retypedPassword" type="password" name="password" id="newPassword2"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>

      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="updatePassword" type="button"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Passwort aktualisieren
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      userSettings: {
        oldPassword: "",
        newPassword: "",
        signerName: "",
        name: "",
        importResults: null,
        importTechnology: null,
        importEvaluation: null,
        retypedPassword: ""
      }
    }
  },
  computed: {
    username() {
      return this.$store.state.user.username
    }
  },
  methods: {
    loadUserSettings() {
      this.$http
          .get('/api/v1/user/' + this.username)
          .then(response => {
            console.log("User:")
            console.log(response)
            this.userSettings = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Error loading user!")
          })
    },
    updatePassword() {
      if (this.userSettings.oldPassword && this.userSettings.newPassword && this.userSettings.newPassword === this.userSettings.retypedPassword) {
        let data = {
          oldPassword: this.userSettings.oldPassword,
          newPassword: this.userSettings.newPassword,
          name: this.userSettings.name,
        }
        this.$http
            .post('/api/v1/user/' + this.username, data)
            .then(response => {
              console.log("User:")
              console.log(response)
              console.log(this.userSettings.newPassword)
              alert("Successfully updated password.")
              this.userSettings.oldPassword = ""
              this.userSettings.newPassword = ""
              this.userSettings.retypedPassword = ""

            })
            .catch(reason => {
              console.log(reason)
              alert("Error updating password!")
            })
      } else {
        alert("You specified values for a new password. Please check those fields again.")
      }
    },
    updateUser() {
      let data = {
        signerName: this.userSettings.signerName,
        name: this.userSettings.name,
        importResults: this.userSettings.importResults,
        importTechnology: this.userSettings.importTechnology,
        importEvaluation: this.userSettings.importEvaluation
      }
      this.$http
          .post('/api/v1/user/' + this.username, data)
          .then(response => {
            console.log("User:")
            console.log(response)
            alert("Successfully updated user information.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Error updating user information!")
          })
    }
  },

  mounted() {
    this.loadUserSettings()
  }
}
</script>

<style scoped>

</style>