<template>

  <div>
    <h1>Rest Interface</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
      <div class="shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-6 gap-6">

            <div class="col-span-6 sm:col-span-6">
              <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
              <input v-model="restInterface.name" type="text" name="name" id="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
              <textarea v-model="restInterface.description" name="description" id="description" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="className" class="block text-sm font-medium text-gray-700">Service</label>
              <select v-model="restInterface.className" name="className" id="className" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                <option value="de.jungdiagnostics.repository.communication.rest.JdxManagerRestNode">JDX Manager</option>
              </select>
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="url" class="block text-sm font-medium text-gray-700">Host address</label>
              <input v-model="restInterface.url" type="text" name="url" id="url" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
              <input v-model="restInterface.username" type="text" name="username" id="username" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
              <input v-model="restInterface.password" type="password" name="password" id="password" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="isSource" class="inline text-sm font-medium text-gray-700">Interface is data source</label>
              <input v-model="restInterface.isSource" type="checkbox" name="isSource" id="isSource" class="inline m-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="isDestination" class="inline text-sm font-medium text-gray-700">Interface is data destination</label>
              <input v-model="restInterface.isDestination" type="checkbox" name="isDestination" id="isDestination" class="inline m-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

          </div>
        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button @click="save()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
  </div>

</template>

<script>
export default {
  name: "RestInterface",
  data() {
    return {
      restInterface: {
        url: "",
        name: "",
        description: "",
        enabled: true,
        username: "",
        password: "",
        isDestination: false,
        isSource: false
      }
    }
  },
  methods: {
    validFormInputs() {
      let hostAetNameValid = this.restInterface.url !== undefined && this.restInterface.url.length > 0 &&
          this.restInterface.name !== undefined && this.restInterface.name.length > 0;

      return hostAetNameValid && (this.restInterface.isDestination || this.restInterface.isSource);
    },
    save() {
      if (this.validFormInputs()) {
        console.log(this.restInterface)
        this.$http
            .put('/api/v1/configuration/rest/interface', this.restInterface)
            .then(response => {
              console.log(response);
              this.$router.push({name: 'settings', params: {currentTab: 'restInterfaces'}})
            })
            .catch(reason => {
              console.log(reason)
              this.savingDraft = false
              alert("Error saving draft!")
            })
      } else {
        alert("Please check your inputs.");
      }
    }
  }
}
</script>

<style scoped>

</style>