<template>
  <div>
    <h1>Nutzerinformationen</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6 sm:col-span-6">
            <label for="signerName" class="block text-sm font-medium text-gray-700">
              <b>Unterzeichner</b><br>
              Name, der auf dem Bericht angezeigt wird
            </label>
            <input v-model="userSettings.signerName" name="signerName" id="signerName"
                   class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700">
              <b>Name</b><br>
              Vor- und Nachname des Nutzers. Dieser Name wird neben Berichten als Bearbeiter und auf Berichten angezeigt, wenn der Name des Unterzeichners nicht vorhanden ist.
            </label>
            <input v-model="userSettings.name" name="name" id="name"
                   class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="updateUser" type="button"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Nutzer aktualisieren
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "UserSettings",
  data() {
    return {
      userSettings: {
        oldPassword: "",
        newPassword: "",
        signerName: "",
        name: "",
        importResults: null,
        importTechnology: null,
        importEvaluation: null,
        retypedPassword: ""
      }
    }
  },
  computed: {
    username() {
      return this.$store.state.user.username
    }
  },
  methods: {
    loadUserSettings() {
      this.$http
          .get('/api/v1/user/' + this.username)
          .then(response => {
            console.log("User:")
            console.log(response)
            this.userSettings = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Nutzer konnte nicht geladen werden!")
          })
    },
    updateUser() {
      let data = {
        signerName: this.userSettings.signerName,
        name: this.userSettings.name,
        importResults: this.userSettings.importResults,
        importTechnology: this.userSettings.importTechnology,
        importEvaluation: this.userSettings.importEvaluation
      }
      this.$http
          .post('/api/v1/user/' + this.username, data)
          .then(response => {
            console.log("Nutzer:")
            console.log(response)
            alert("Die Nutzerinformationen wurden erfolgreich aktualisiert.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Aktualisieren der Nutzerinformationen ist ein Fehler aufgetreten.")
            this.loadUserSettings()
          })
    }
  },
  mounted() {
    this.loadUserSettings()
  }
}
</script>

<style scoped>

</style>