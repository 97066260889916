<template>

  <h1>Neuen Button erstellen</h1>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label for="buttonName" class="block text-sm font-medium text-gray-700">
              <b>Button Name <a href="#" class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                             data-bs-toggle="tooltip" title="Wähle einen eindeutigen Namen für den Button.">*</a></b><br>
              Name, mit dem Button unter Berichtsfeld angezeigt wird.
            </label>
            <input v-model="medicalReportButton.buttonName" type="text" name="buttonName" id="buttonName" class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

          <div class="col-span-6">
            <label for="buttonText" class="block text-sm font-medium text-gray-700">
              <b>Button Text <a href="#"  class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                            ></a></b><br>
              Text, der in Bericht eingefügt wird, wenn Button verwendet wird.
            </label>
            <input v-model="medicalReportButton.buttonText" type="text" name="buttonText" id="buttonText" class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>

      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="create()" type="button"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Button erstellen
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "User",
  data() {
    return {
      medicalReportButton: {
        buttonName: "",
        buttonText: "",
      }
    }
  },
  methods: {
    create() {
      this.$http
          .post('/api/v1/medicalReportButton/', this.medicalReportButton)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              alert("Button erfolgreich angelegt."+ "Name: " + response.data.buttonName);
              this.$router.push({name: 'settings', params: {currentTab: 'medicalReportButtons'}})
            } else {
              alert("Beim Speichern des Nutzers ist ein Fehler aufgetreten!")
            }
            this.medicalReportButton = response.data
          })
          .catch(reason => {
            console.log(reason)
            if(this.medicalReportButton.buttonName === ""){
              alert("Beim Erstellen des Buttons ist ein Fehler aufgetreten. Der Button benötigt einen Namen.")
            }
            else if(this.medicalReportButton.buttonText === ""){
              alert("Beim Erstellen des Buttons ist ein Fehler aufgetreten. Der Button benötigt einen Text, der in den Bericht eingefügt werden kann.")
            }
          })
    }
  }
}
</script>

<style scoped>

</style>