<template>
  <div>
    <h1>Report Einstellungen</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

        </div>
        <div class="col-span-6 sm:col-span-6">
          <label for="importTechnology" class="block text-sm font-medium text-gray-700">
            <b>Technologie einfügen</b><br>
            Verwendete Technologie wird mit in Zwischenablage kopiert.
          </label>
          <input type="checkbox" v-model="userSettings.importTechnology" name="importTechnology" id="importTechnology" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
        </div>
        <br>
        <div class="col-span-6 sm:col-span-6">
          <label for="importResults" class="block text-sm font-medium text-gray-700">
            <b>Medizinischen Befund einfügen</b><br>
            Medizinischer Befund wird mit in Zwischenablage kopiert.
          </label>
          <input type="checkbox" v-model="userSettings.importResults" name="importResults" id="importResults"
                 class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
        </div>
        <br>
        <div class="col-span-6 sm:col-span-6">
          <label for="importEvaluation" class="block text-sm font-medium text-gray-700">
            <b>Auswertung einfügen </b><br>
            Auswertung wird mit in Zwischenablage kopiert.
          </label>
          <input type="checkbox" v-model="userSettings.importEvaluation" name="importEvaluation" id="importEvaluation"
                 class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"/>
        </div>
        <br>
        <div class="col-span-6 sm:col-span-6">
          <label for="imagePreviewType" class="block text-sm font-medium text-gray-700">
            <b>Bild Vorschau Format</b><br>
            Das Format in dem die Vorschaubilder angezeigt werden sollen.
            Die Bilder können nur im PNG-Format aus dem Bericht in das Clipboard kopiert werden.
          </label>
          <select id="imagePreviewType" v-model="userSettings.imagePreviewType">
            <option v-for="imageType in imagePreviewTypes" :key="imageType"
                    :value="imageType">
              {{ imageType }}
            </option>
          </select>
        </div>
        <br>
        <div class="col-span-6 sm:col-span-6">
          <label for="imagePreviewSize" class="block text-sm font-medium text-gray-700">
            <b>Bild Vorschau Größe</b><br>
            Die Größe in der die Vorschaubilder angezeigt werden sollen.
          </label>
          <select id="imagePreviewSize" v-model="userSettings.imagePreviewSize">
            <option v-for="imageSize in imagePreviewSizes" :key="imageSize"
                    :value="imageSize">
              {{ imageSize }}
            </option>
          </select>
        </div>
      </div>

      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="updateUser" type="button"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Nutzer aktualisieren
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "UserSettings",
  data() {
    return {
      userSettings: {
        oldPassword: "",
        newPassword: "",
        signerName: "",
        name: "",
        importResults: null,
        importTechnology: null,
        importEvaluation: null,
        imagePreviewType: null,
        imagePreviewSize: null,
        retypedPassword: "",
        exportDefinitionType: null
      },
      imagePreviewTypes: [],
      imagePreviewSizes: [],
      exportDefinitionTypes: []
    }
  },
  computed: {
    username() {
      return this.$store.state.user.username
    }
  },
  methods: {
    loadUserSettings() {
      this.$http
          .get('/api/v1/user/' + this.username)
          .then(response => {
            console.log("User:")
            console.log(response)
            this.userSettings = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Error loading user!")
          })
    },
    loadImagePreviewTypes() {
      this.$http
          .get('/api/v1/report/imagePreviewTypes/')
          .then(response => {
            this.imagePreviewTypes = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der ImagePreviewTypes ist ein Fehler aufgetreten!")
          })
    },
    loadImagePreviewSizes() {
      this.$http
          .get('/api/v1/report/imagePreviewSizes/')
          .then(response => {
            this.imagePreviewSizes = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der ImagePreviewSizes ist ein Fehler aufgetreten!")
          })
    },
    loadExportDefinitionType() {
      this.$http
          .get('/api/v1/report/exportDefinitionType/')
          .then(response => {
            this.exportDefinitionTypes = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der ImagePreviewTypes ist ein Fehler aufgetreten!")
          })
    },
    updateUser() {
      let data = {
        signerName: this.userSettings.signerName,
        name: this.userSettings.name,
        importResults: this.userSettings.importResults,
        importTechnology: this.userSettings.importTechnology,
        importEvaluation: this.userSettings.importEvaluation,
        imagePreviewType: this.userSettings.imagePreviewType,
        imagePreviewSize: this.userSettings.imagePreviewSize
      }
      this.$http
          .post('/api/v1/user/' + this.username, data)
          .then(response => {
            console.log("User:")
            console.log(response)
            alert("Successfully updated user information.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Error updating user information!")
          })
    }
  },
  mounted() {
    this.loadUserSettings()
    this.loadImagePreviewTypes()
    this.loadImagePreviewSizes()
    this.loadExportDefinitionType()
  }
}
</script>

<style scoped>

</style>