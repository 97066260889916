<template>

  <div>
    <h1>Empfänger</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Adresse
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Dicom-Repräsentation
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">

            <tr>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="flex items-center">
<!--                  <div class="flex-shrink-0 h-10 w-10">-->
<!--                    <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="">-->
<!--                  </div>-->
                  <div>
                    <div class="text-sm font-medium text-gray-900">
                      Praxis Jane Cooper
                    </div>
                    <div class="text-sm text-gray-500">
                      jane.cooper@example.com
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">Praxis Dr. Cooper</div>
                <div class="text-sm text-gray-900">Berliner Str. 123</div>
                <div class="text-sm text-gray-900">12345 Berlin</div>
<!--                <div class="text-sm text-gray-500">Optimization</div>-->
              </td>
              <td class="px-4 py-4">
                <span class="mx-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Dr. Cooper
                </span>
                <span class="mx-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Praxis Dr. Cooper
                </span>
                <span class="mx-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Neuro-Praxis Dr. Cooper
                </span>
              </td>
              <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a href="#" class="text-indigo-600 hover:text-indigo-900">Bearbeiten</a>
              </td>
            </tr>

            <tr>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <!--                  <div class="flex-shrink-0 h-10 w-10">-->
                  <!--                    <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="">-->
                  <!--                  </div>-->
                  <div>
                    <div class="text-sm font-medium text-gray-900">
                      Neuro-Praxis John Smith
                    </div>
                    <div class="text-sm text-gray-500">
                      john.smith@example.com
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900">Neurologische Praxis Dr. Smith</div>
                <div class="text-sm text-gray-900">Berliner Str. 123</div>
                <div class="text-sm text-gray-900">12345 Berlin</div>
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <span class="mx-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Dr. Smith
                </span>
                <span class="mx-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                  Neurologische Praxis Dr. Smith
                </span>
              </td>
              <td class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a href="#" class="text-indigo-600 hover:text-indigo-900">Bearbeiten</a>
              </td>
            </tr>

            <!-- More items... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "AddressSettings"
}
</script>

<style scoped>
  h1{
    margin: 0.5rem auto;
    text-align: left;
    font-weight: bold;
  }
</style>