<template>
  <div>
    <h1>JobManager</h1>
  </div>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <form action="#" method="POST">
      <div class="shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-6 gap-6">

            <div class="col-span-6 sm:col-span-6">
              <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
              <input v-model="jobManager.name" type="text" name="name" id="name"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="url" class="block text-sm font-medium text-gray-700">URL</label>
              <input v-model="jobManager.url" name="url" id="url"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="reportTransmissionEndpoint" class="block text-sm font-medium text-gray-700">ReportX
                Transmission Endpoint</label>
              <input v-model="jobManager.reportTransmissionEndpoint" name="reportTransmissionEndpoint"
                     id="reportTransmissionEndpoint"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-6 sm:col-span-6">
              <label for="authToken" class="block text-sm font-medium text-gray-700">Authentication Token</label>
              <input v-model="jobManager.authToken" type="text" name="authToken" id="authToken"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

          </div>
        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button @click="update()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>

</template>

<script>
export default {
  name: "JobManager",
  data() {
    return {
      jobManager: {
        id: undefined,
        name: "",
        url: "",
        reportTransmissionEndpoint: "",
        authToken: ""
      }
    }
  },
  methods: {
    validFormInputs() {
      return this.jobManager.name !== undefined && this.jobManager.name.length > 0 &&
          this.jobManager.url !== undefined && this.jobManager.url.length > 0 &&
          this.jobManager.authToken.length > 0;
    },
    update() {
      if (this.validFormInputs()) {
        this.$http
            .put('/api/v1/configuration/jobManager/update/' + this.jobManager.id, this.jobManager)
            .then(response => {
              console.log(response);
              this.$router.go(0);
            })
            .catch(reason => {
              console.log(reason)
              alert("Error saving JobManager!")
            })
      } else {
        alert("Please check your inputs.");
      }
    }
  },
  mounted() {
    console.log("mounted")
    this.$http
        .get('/api/v1/configuration/jobManager/')
        .then(response => {
          console.log(response);
          this.jobManager = response.data
        })
        .catch(reason => {
          console.log(reason)
          alert("Error loading local dicom device!")
        })
  }
}
</script>

<style scoped>

</style>