<template>

  <h1>Scanner</h1>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700">
              <b>Name</b><br>
              Name
            </label>
            <input v-model="scanner.name" type="text" name="name" id="name"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

          <div class="col-span-6 xl:col-span-6">
            <label class="block text-sm font-medium text-gray-700">Institution</label>
            <VueMultiselect  v-model="scanner.institution"
                             :options="institutions"
                             :close-on-select="true"
                             :clear-on-select="true"
                             :show-labels="true"
                             :searchable="searchable"
                             @search-change="searchInstitution"
                             placeholder="Select one"
                             label="name"
                             track-by="id"
                             open-direction="bottom"
                             noOptions="Type to search"
            />
          </div>

          <div class="col-span-6">
            <label for="deviceSerialNumber" class="block text-sm font-medium text-gray-700">
              <b>Geräte Seriennummer</b><br>
              Eindeutige Nummer, um Scanner zu identifizieren.
            </label>
            <input v-model="scanner.deviceSerialNumber" type="text" name="deviceSerialNumber" id="deviceSerialNumber"
                   class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

        </div>

        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button v-if="!scanner.id" @click="create()" type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Scanner Erstellen
          </button>
          <button v-if="scanner.id" @click="update()" type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Scanner Aktualisieren
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Scanner",
  data() {
    return {
      scanner: {
        id: null,
        name: "",
        institution: null,
        deviceSerialNumber: ""
      },
      institutions: []
    }
  },
  methods: {
    searchInstitution(query){
      this.$http
          .get('/api/v1/institution/search?query='+query)
          .then(response => {
            console.log("Institutions:");
            console.log(response);
            this.institutions = response.data;
            console.log(this.institutions)
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden der Institutionen ist ein Fehler aufgetreten!")
          })
    },
    create() {
      this.$http
          .post('/api/v1/scanner/', this.scanner)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              alert("Scanner wurde erfolgreich erstellt. ID: " + response.data.id)
              this.scanner = response.data
              this.$router.push({name: 'settings', params: {currentTab: 'scanner', id: response.data.id}})
            } else {
              alert("Beim Speichern des Scanners ist ein Fehler aufgetreten.")
            }
          })
          .catch(reason => {
            console.log(reason.response.data._embedded.errors)
            let message = JSON.parse(reason.response.request.response)._embedded.errors[0].message
            if(message.startsWith("scanner.name")){
              alert(message.replace("scanner.name:", "Name"))
            }
            else if(message.startsWith("scanner.institution")){
              alert(message.replace("scanner.institution:", "Institution"))
            }
            else if(message.startsWith("scanner.deviceSerialNumber")){
              alert(message.replace("scanner.deviceSerialNumber:", "Scanner device serialnumber"))
            }
            else{
              alert("Uncaught error occurred: "+message)
            }
          })
    },
    update() {
      console.log(this.scanner)
      this.$http
          .post('/api/v1/scanner/' + this.scanner.id, this.scanner)
          .then(response => {
            console.log(response)
            this.scanner = response.data
            alert("Scanner wurde erfolgreich aktualisiert.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Aktualisieren des Scanners ist ein Fehler aufgetreten!")
          })
    },
    loadScanner() {
      if (!this.$route.params.id) return;
      this.$http
          .get('/api/v1/scanner/' + this.$route.params.id)
          .then(response => {
            console.log(response)
            this.scanner = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Laden des Scanners ist ein Fehler aufgetreten.!")
          })
    }
  },
  mounted() {
    this.loadScanner();
  }
}
</script>

<style scoped>

</style>