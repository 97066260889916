<template>

  <h1>Workflow</h1>

  <div class="mt-5 md:mt-0 md:col-span-2">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">

          <div class="col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <input v-model="workflow.name" type="text" name="name" id="name" class="mt-1 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="description" class="block text-sm font-medium text-gray-700">Gerätebeschreibung</label>
            <textarea v-model="workflow.description" name="description" id="description" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>

          <div class="col-span-6 xl:col-span-2">
            <label for="source" class="block text-sm font-medium text-gray-700">Quelle</label>
            <select v-model="workflow.source" id="source" name="source.id" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option selected disabled value="0">Bitte auswählen</option>
              <optgroup label="Dicom Devices" v-if="sources.dicomDevices.length > 0">
                <option v-for="dicomSource in sources.dicomDevices" :key="dicomSource.id" :value="dicomSource">{{dicomSource.name}}</option>
              </optgroup>
              <optgroup label="Rest Interfaces" v-if="sources.restInterfaces.length > 0">
                <option v-for="restSource in sources.restInterfaces" :key="restSource.id" :value="restSource">{{restSource.name}}</option>
              </optgroup>
            </select>

          </div>

          <div class="xl:col-span-2">
            <div class="text-gray-700 w-full text-center"> <br> &#8680; ReportX &#8680; </div>
          </div>

          <div class="col-span-6 xl:col-span-2">
            <label for="destination" class="block text-sm font-medium text-gray-700">Ziel</label>
            <select v-model="workflow.destinations" id="destination" multiple="multiple" name="destination" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="0" disabled>Nur in der Datenbank speichern</option>
              <optgroup label="Dicom Devices" v-if="destinations.dicomDevices.length > 0">
                <option v-for="dicomDestination in destinations.dicomDevices" :key="dicomDestination.id" :value="dicomDestination">{{dicomDestination.name}}</option>
<!--                <option value="volvo">Volvo</option>-->
<!--                <option value="saab">Saab</option>-->
              </optgroup>
              <optgroup label="Rest Interfaces" v-if="destinations.restInterfaces.length > 0">
                <option v-for="restDestination in destinations.restInterfaces" :key="restDestination.id" :value="restDestination">{{restDestination.name}}</option>
<!--                <option value="volvo">Volvo</option>-->
<!--                <option value="saab">Saab</option>-->
              </optgroup>
            </select>
          </div>
        </div>
      </div>

      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button @click="save()" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Speichern
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Workflow",
  data() {
    return {
      workflow: {
        name: "",
        description: "",
        source: {

        },
        destinations: [

        ]
      },
      sources: {
        dicomDevices: [],
        restInterfaces: []
      },
      destinations: {
        dicomDevices: [],
        restInterfaces: []
      }
    }
  },
  methods: {
    save() {
      console.log(this.workflow)
      this.$http
          .put('/api/v1/configuration/workflowConfiguration/workflow/' + this.workflow.id, this.workflow)
          .then(response => {
            console.log(response)
            this.$router.go(0);
          })
          .catch(reason => {
            console.log(reason)
            alert("Error loading workflow!")
          })
    },
    loadWorkflow() {
      this.$http
          .get('/api/v1/configuration/workflowConfiguration/workflow')
          .then(response => {
            console.log(response)
            let w = response.data
            if (w.source === undefined) {
              w["source"] = {id : 0}
            }
            if (w.destinations === undefined) {
              w["destinations"] = {id : 0}
            }
            this.workflow = response.data
          })
          .catch(reason => {
            console.log(reason)
            alert("Error loading workflow!")
          })
    },
    initSelections() {
      this.$http
          .get('/api/v1/configuration/workflowConfiguration/workflowNode')
          .then(response => {
            console.log("Available Workflow Nodes:")
            console.log(response)
            for (let i = 0; i < response.data.length; i++) {

              if (response.data[i].isSource === true) {
                if (response.data[i].nodeType === "DICOM") {
                  this.sources.dicomDevices.push(response.data[i]);
                }
                if (response.data[i].nodeType === "REST") {
                  this.sources.restInterfaces.push(response.data[i]);
                }
              }

              if (response.data[i].isDestination === true) {
                if (response.data[i].nodeType === "DICOM") {
                  this.destinations.dicomDevices.push(response.data[i]);
                }
                if (response.data[i].nodeType === "REST") {
                  this.destinations.restInterfaces.push(response.data[i]);
                }
              }

            }
          })
          .catch(reason => {
            console.log(reason)
            this.savingDraft = false
            alert("Error loading local dicom device!")
          })
    }
  },
  mounted() {
    this.initSelections();
    this.loadWorkflow();
  }
}
</script>

<style scoped>

</style>