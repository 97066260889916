<template>
  <div>
    <h1>Befund-Buttons verwalten</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Button-Text
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Speichern
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Löschen
              </th>

            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
            <tr v-for="button in buttons" :key="button.id">


              <td class="px-4 py-4 whitespace-nowrap"><textarea id="reportButton_name" name="report-text" rows="1"
                                                                class="border-white-100 w-full"
                                                                autofocus v-model="button.buttonName"></textarea></td>

              <td class="px-4 py-4 whitespace-nowrap"><textarea id="reportButton_text" name="report-text" rows="2"
                                                                class="border-white-100 w-full"
                                                                autofocus v-model="button.buttonText"></textarea></td>
              <td class="px-4 py-4 whitespace-nowrap"><input @click="updateButton(button.id, button.buttonText, button.buttonName)" type="button"
                                                             value="Speichern"/></td>
              <td class="px-4 py-4 whitespace-nowrap"><input @click="deleteButton(button.id)" type="button"
                                                             value="Löschen"/></td>

            </tr>
            <tr>
              <td colspan="8" class="px-4 py-4 whitespace-nowrap">
                <!-- TODO! -->
                <router-link :to="{ name: 'settings', params: { currentTab: 'medicalReportButtonsCreate' }}">
                  <b> + </b> Neuen Button erstellen
                </router-link>
              </td>
            </tr>
            <tr>
              <td colspan="8" class="px-4 py-4 whitespace-nowrap">
                <div class="flex justify-center items-baseline">
                  <span class="px-4 py-4 whitespace-nowrap">
                    <input @click="decreasePage" v-if="page > 0" type="button" value="Zurück"/>
                    <input @click="decreasePage" v-if="!page > 0" type="button" disabled value="Zurück"/>
                  </span>
                  <span class="px-4 py-4 whitespace-nowrap">
                    {{page+1}} / {{totalPages}}
                  </span>
                  <span class="px-4 py-4 whitespace-nowrap">
                    <input @click="increasePage" v-if="totalPages-1>page" type="button" value="Weiter"/>
                    <input @click="increasePage" v-if="!(totalPages-1>page)" type="button" disabled value="Weiter"/>
                  </span>
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MedicalReportButtons",
  data() {
    return {
      page: 0,
      totalPages: null,
      buttons: []
    }
  },
  computed: {
    username() {
      return this.$store.state.user.username
    }
  },
  methods: {
    loadButtonPage(){
      this.loading = true;
      this.$http
          .get('/api/v1/medicalReportButton?page=' + this.page)
          .then(response => {
            console.log(response.data)
            this.buttons = response.data.content
            if(response.data.totalPages === 0){
              this.totalPages = 1
            }
            else{
              this.totalPages = response.data.totalPages
            }
            this.loading = false;
          })
          .catch(reason => {
            if (reason.status === 401) {
              // handled by axios interceptor
            } else {
              console.log(reason)
              alert("Die Verbindung zum Server ist fehlgeschlagen. Bitte versuchen Sie es erneut.")
            }
            this.loading = false;
          });

    },
    increasePage() {
      this.page++
      this.loadButtonPage()
    },
    decreasePage() {
      this.page--
      this.loadButtonPage()
    },

    deleteButton(id){
      this.$http
        .delete('/api/v1/medicalReportButton/' + id)
        .then(response =>{
          this.buttons = this.buttons.filter(button => button.id !== id)
          console.log(response)
          alert("Button wurde erfolgreich gelöscht.")
        })
      .catch(reason => {
        console.log(reason)
        alert("Beim Löschen des Buttons ist ein Fehler aufgetreten.")
      })
    },

    updateButton(id, text, name) {
      console.log("here" + id + text + name)
      let data = {
        buttonName: name,
        buttonText: text
      }
      console.log(data)
      this.$http
          .post('/api/v1/medicalReportButton/' + id, data)
          .then(response => {
            console.log(response)
            alert("Button wurde erfolgreich bearbeitet.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Beim Speichern des Buttons ist ein Fehler aufgetreten.")
          })
    }
  },
  mounted() {
    this.loadButtonPage()
  }
}
</script>

<style scoped>

</style>