<template>

  <div>
    <h1>Institution</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Anzeigen
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:text-gray-900">
            <tr v-for="institution in institutionList" :key="institution.id">
              <td class="px-4 py-4 whitespace-nowrap">{{institution?.id}}</td>
              <td class="px-4 py-4 whitespace-nowrap">{{institution?.name}}</td>
              <td class="px-4 py-4 whitespace-nowrap"> <input @click="showDetails(institution.id)" type="button" value="Anzeigen"/></td>
            </tr>
            <tr>
              <td colspan="8" class="px-4 py-4 whitespace-nowrap">
                <!-- TODO! -->
                <router-link :to="{ name: 'settings', params: { currentTab: 'institution' }}">
                  <b> + </b> Institution hinzufügen
                </router-link>
              </td>
            </tr>
            <tr>
              <td colspan="8" class="px-4 py-4 whitespace-nowrap">
                <div class="flex justify-center items-baseline">
                  <span class="px-4 py-4 whitespace-nowrap">
                    <input @click="decreasePage" v-if="page > 0" type="button" value="Zurück"/>
                    <input @click="decreasePage" v-if="!page > 0" type="button" disabled value="Zurück"/>
                  </span>
                  <span class="px-4 py-4 whitespace-nowrap">
                    {{page+1}} / {{totalPages}}
                  </span>
                  <span class="px-4 py-4 whitespace-nowrap">
                    <input @click="increasePage" v-if="totalPages-1>page" type="button" value="Weiter"/>
                    <input @click="increasePage" v-if="!(totalPages-1>page)" type="button" disabled value="Weiter"/>
                  </span>
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <loading-indicator :loading="loading" message="Dicom Devices werden geladen..."></loading-indicator>

</template>

<script>
export default {
  name: "InstitutionList",
  data() {
    return {
      loading: true,
      page: 0,
      institutionList: [],
      totalPages: 0
    }
  },
  methods: {
    increasePage() {
      this.page++
      this.loadInstitutionPage()
    },
    decreasePage() {
      this.page--
      this.loadInstitutionPage()
    },
    showDetails(id) {
      this.$router.push({ name: 'settings', params: { currentTab: 'institution', id: id} });
    },
    toNewDevice() {
      this.$router.push({ name: 'settings', params: { currentTab: 'institution', id: 0} });
    },
    loadInstitutionPage(){
      this.loading = true;
      this.$http
          .get('/api/v1/institution?page=' + this.page)
          .then(response => {
            console.log(response.data)
            this.institutionList = response.data.content
            this.totalPages = response.data.totalPages
            this.loading = false;
          })
          .catch(reason => {
            if (reason.status === 401) {
          // handled by axios interceptor
            } else {
              console.log(reason)
              alert("Die Verbindung zum Server ist fehlgeschlagen. Bitte versuchen Sie es erneut.")
            }
            this.loading = false;
      });
    }
  },
  mounted() {
    this.loadInstitutionPage()
  }
}
</script>

<style scoped>

</style>