<template>

  <div>
    <h1>Appearance Settings</h1>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" style="width: 25%" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Option
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Wert
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">

            <tr>
              <td class="px-4 py-4 whitespace-nowrap dark:text-gray-900">
                Dark Mode
              </td>
              <td class="px-4 py-4 whitespace-nowrap">
                <input type="checkbox" id="darkMode" name="darkMode" value="darkMode" v-model="darkMode" v-on:change="setDarkMode" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "AppearanceSettings.vue",
  data() {
    return {
      darkMode: false,
      oldPassword: "",
      password: "",
      retypedPassword: ""
    }
  },
  computed: {
    username() {
      return this.$store.state.user.username
    }
  },
  methods: {
    setDarkMode() {
      console.log(this.darkMode)

      let userSettings = {
        // "userSettings": {
          "darkMode": this.darkMode
        // }
      }

      console.log("userSettings")
      console.log(userSettings)

      this.$http.put('/api/v1/user/appearance/' + this.username , userSettings)
          .then(response => {
            console.log(response);
          })
          .catch(reason => {
            console.log(reason)
            alert("Error saving user settings!")
          })

      if (this.darkMode) {
        this.$store.commit("user/setDarkMode", true)
        // Storage.setDarkMode(true)
      } else {
        this.$store.commit("user/setDarkMode", false)
      }
    },
  },
  mounted() {
    this.darkMode = this.$store.state.user.darkMode
  }
}

</script>

<style scoped>
  h1{
    margin: 0.5rem auto;
    text-align: left;
    font-weight: bold;
  }
</style>